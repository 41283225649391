.altai-sidebar li {
  padding: 0 !important;
}
.altai-sidebar-collection {
  list-style: none;
  padding-left: 0;
}
.altai-sidebar-collection .altai-sidebar-cqollection i {
  color: #808080;
}
.altai-sidebar-itemlabel {
  cursor: pointer;
  padding: 10px;
  padding-left: 25px;
}
.altai-sidebar-itemlabel i {
  color: #0074DC;
}
.altai-sidebar-itemlabel:hover {
  background-color: #efefef;
}
.altai-sidebar-itemlabel-icon {
  margin-right: 10px;
}
.altai-sidebar li ul {
  padding-left: 20px;
}
